<template>
    <div class="container page">
      <div class="header" v-if="!showContent1 && !showContent2">
        <van-nav-bar :title="$t('recharge')" class="nav-bar">
          <template #left>
            <van-icon name="arrow-left" color="#fff" @click="back()"/>
          </template>
        </van-nav-bar>
      </div>
      <div>
        <div v-if="!showContent1 && !showContent2" class="box-recharge">
          <div v-if="!showContent1 && !showContent2" @click="showContent1 = true"><img src="/img/mine/zalopay.webp"/><div>ZALOPAY</div><i class="van-icon van-icon-arrow van-cell__right-icon"></i></div>
          <div v-if="!showContent1 && !showContent2" @click="showContent2 = true"><img src="/img/mine/momo.webp"/><div>MOMO</div><i class="van-icon van-icon-arrow van-cell__right-icon"></i></div>
        </div>

        <div v-if="showContent1">
          <div class="header">
            <van-nav-bar title="ZaloPay" class="nav-bar">
              <template #left>
                <van-icon name="arrow-left" color="#fff" @click="showContent1 = false"/>
              </template>
            </van-nav-bar>
          </div>
          <div class="moneyNumber">
            <span class="moneyType">{{ $t('money') }}</span>
            <van-field v-model="recharge_money1" type="number" :placeholder="$t('pls_money')"/>
          </div>
          <van-button class="recharge_btn" type="default" @click="doRecharge1()">{{ $t('confirm') }}</van-button>
        </div>
        
        <div v-if="showContent2">
          <div class="header">
            <van-nav-bar title="Momo" class="nav-bar">
              <template #left>
                <van-icon name="arrow-left" color="#fff" @click="showContent2 = false"/>
              </template>
            </van-nav-bar>
          </div>
          <div class="moneyNumber">
            <span class="moneyType">{{ $t('money') }}</span>
            <van-field v-model="recharge_money2" type="number" :placeholder="$t('pls_money')"/>
          </div>
          <van-button class="recharge_btn" type="default" @click="doRecharge2()">{{ $t('confirm') }}</van-button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        userInfo:{
        },
        showContent1: false,
        showContent2: false,
        recharge_money1:"",
        recharge_money2:"",
      };
    },
    methods: {
      back(){
        return window.history.back();
      },
      getUserInfo(){
        this.$http({
          method: 'get',
          url: 'user_info'
        }).then(res=>{
          if(res.code === 200){
              this.userInfo = res.data;
              this.menu_top = 15;
              if(this.userInfo.status !== 1){
                this.$toast(this.t('offline'));
                localStorage.removeItem('token')
                this.$router.push({path:'/Login'})
              }
          }else if(res.code ===401){
            this.$toast(res.msg);
          }
        })
      },
      doRecharge1(){
        if(this.recharge_money1 <= 0){
          this.$toast(this.$t('pls_money'));
          return false;
        }else {
          this.$http({
            method: 'post',
            data:{money:this.recharge_money1,payment:"ZALOPAY"},
            url: 'user_set_recharge'
          }).then(res=>{
            if(res.code === 200){
              location. reload()
              this.$toast(res.msg);
              this.getUserInfo();
            }else if(res.code ===401){
              this.$toast(res.msg);
            }
          })
        }
      },
      doRecharge2(){
        if(this.recharge_money2 <= 0){
          this.$toast(this.$t('pls_money'));
          return false;
        }else {
          this.$http({
            method: 'post',
            data:{money:this.recharge_money2,payment:"MOMO"},
            url: 'user_set_recharge'
          }).then(res=>{
            if(res.code === 200){
              location. reload()
              this.$toast(res.msg);
              this.getUserInfo();
            }else if(res.code ===401){
              this.$toast(res.msg);
            }
          })
        }
      },
    },  
    created() {
      if(localStorage.getItem('token')){
          this.getUserInfo();
      }else {
        this.userInfo.username = this.$t('login_regis');
        this.userInfo.ip = this.$t('text_login_regis');
        this.userInfo.header_img = "img/mine/avatar.png";
      }
    }
  };
  </script>
  
<style lang='less' scoped>
@import "../../assets/css/base.css";
.box-recharge {
  padding: 15px;
}

.box-recharge > div {
  text-align: center;
  background: #fff;
  display: flex;
  align-items: center;
  padding: 10px;
  margin-bottom: 15px;
}
.box-recharge img {
  max-width: 40px;
  border-radius: 10px;
  margin-right: 20px;
}
.box-recharge i {
  margin-left: auto;
  font-size: 18px;
}
.moneyNumber {
  padding: 0 10px;
  margin: 15px;
  display: flex;
  align-items: center;
  background: #fff;
  font-size: 1rem;
}
.moneyType {
  width: 70px;
}
.recharge_btn {
  background: #d80b2a;
  color: #f2f2f2;
  font-size: 1.1rem;
  width: 50%;
  margin: 0 25% 15px;
}
</style>
  