<template>
	<div class="container page">
		<van-nav-bar :title="$t('history_play')" class="nav-bar">
			<template #left>
				<van-icon name="arrow-left" color="#fff" @click="back()" />
			</template>
		</van-nav-bar>
		<div class="main">
			<van-pull-refresh v-model="isLoading">
				<van-sidebar @change="onChange" v-model="activeKey" class="tab-history">
					<van-sidebar-item :title="$t('all')" />
					<van-sidebar-item :title="$t('success')" />
					<van-sidebar-item :title="$t('unfinished')" />
					<van-sidebar-item :title="$t('process')" />
				</van-sidebar>
				<van-empty v-if="list.length === 0" :description="$t('no_data')" />
				<div v-else>
					<div class="list-history">
						<div class="item_list" v-for="(v, key) in list" :key="key">
							<div class="lottery_info">
								<span class="period-number">{{ v.name }}</span>
								<span class="period-number">{{ v.expect }}</span>
								<span
									class="period-number"
									v-if="
										v.data.some(item => {
											return item.win_text == $t('matched');
										})
									"
									style="color: #07c160"
									>{{ $t('matched') }}</span
								>
								<span
									class="period-number"
									v-else-if="
										v.data.some(item => {
											return item.win_text == $t('matching');
										})
									"
									style="color: #f1b207"
									>{{ $t('matching') }}</span
								>
								<span class="period-number" v-else>{{ $t('not_matched') }}</span>
							</div>
							<div class="line">
								{{ $t('NO') }}: <span>{{ v.expect }}</span>
							</div>
							<div class="line">
								{{ $t('orders') }}:
								<span>{{
									v.data
										.map(item => {
											return item.type == "1" ? "A" : item.type == "2" ? "B" : item.type == "3" ? "C" : item.type == "4" ? "D" : item.type;
										})
										.join(", ")
								}}</span>
							</div>
							<div class="line">
								{{ $t('money') }}: <span>{{ Number(v.money).toLocaleString() }}</span
								>, {{ $t('orders_num') }} <span>{{ v.count }}</span
								>, {{ $t('money_win') }} <span>{{ Number(v.data[0].profit).toLocaleString() }}</span
								>, {{ $t('orders_win') }} <span>{{ v.data.filter(item => item.is_win == 1).length }}</span>
							</div>
						</div>
					</div>
				</div>
			</van-pull-refresh>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			isLoading: false,
			list: [],
			activeKey: 0,
		};
	},
	methods: {
		back() {
			this.$router.push({path: "/Home"});
		},
		onRefresh() {
			setTimeout(() => {
				this.$toast(this.$t('renew'));
				this.isLoading = false;
			}, 500);
		},
		onChange(index) {
			this.$http({
				method: "get",
				data: {class: index},
				url: "user_get_game_list",
			}).then(res => {
				console.log(res.data);
				if (index == 0) {
					this.list = res.data;
				} else if (index == 1) {
					this.list = res.data.filter(item => {
						return item.win_text == this.$t('matched');
					});
				} else if (index == 2) {
					this.list = res.data.filter(item => {
						return item.win_text == this.$t('not_matched');
					});
				} else if (index == 3) {
					this.list = res.data.filter(item => {
						return item.win_text == this.$t('matching');
					});
				}
			});
		},
		getUserGameList() {
			this.$http({
				method: "get",
				url: "user_get_game_list",
			}).then(res => {
				if (res.code === 200) {
					this.list = res.data;
				} else if (res.code === 401) {
					this.$toast(res.msg);
				}
			});
		},
	},
	created() {
		if (!localStorage.getItem("token")) {
			this.$router.push({path: "/Login"});
		} else {
			this.getUserGameList();
		}
	},
};
</script>

<style lang="less" scoped>
@import "../../assets/css/base.css";
::v-deep .van-pull-refresh__track .van-pull-refresh__head * {
	color: #000000;
	font-size: 16px;
}
.page {
	background: linear-gradient(1turn, #fff, #ef3340);
}
.nav-bar {
	background: transparent;
}
::v-deep .van-loading__text {
	color: #000000;
	font-size: 16px;
}
.container .main {
	position: relative;
	overflow: auto;
	background: transparent;
	height: 100%;
	padding: 0 10px;
}
.item_list {
	padding: 10px 10px;
	margin: 15px 5px;
	background: #fff;
	border-radius: 10px;
	line-height: 30px;
}

.item_list .topInfo span {
	flex: 1;
	font-size: 15px;
	font-weight: 700;
	color: #ff253f;
}
.item_list .time span {
	flex: 1;
	font-size: 13px;
	font-weight: 500;
	color: #000;
}
.item_list .line {
	font-size: 12px;
	font-weight: 500;
	color: #333;
	border-top: 1px solid #eee;
	padding-top: 3px;
}
.item_list .line span {
	color: #673ab7;
	font-weight: 600;
}
.item_list .desc span {
	font-size: 13px;
	font-weight: 700;
	color: #9b9b9b;
}
.item_list .cover {
	width: 40px;
	height: 40px;
	-o-object-fit: cover;
	object-fit: cover;
}
.item_list .period-number {
	height: 30px;
	line-height: 30px;
	font-size: 16px;
	font-weight: 700;
	color: #000;
}
.item_list .period-number:first-child {
	color: #ff0000;
}
.item_list .period-number:nth-child(2) {
	color: #673ab7;
}
.item_list .lottery_info {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-bottom: 3px;
}
.recent {
	display: flex;
	align-items: center;
	height: 40px;
}
.kuaisan-ball .left {
	justify-content: flex-start;
}
.kuaisan-ball {
	flex: 1;
	display: flex;
	align-items: center;
}

.kuaisan-ball .res-img {
	width: 30px;
	height: 30px;
	margin-right: 15px;
}
.kuaisan-ball .res-des {
	font-weight: 700;
	text-align: center;
	color: #000;
}
.kuaisan-ball .res-des.middle {
	margin-left: 10px;
	font-size: 15px;
	text-align: left;
}
.tab-history {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	justify-content: center;
	align-items: center;
	text-align: center;
}
.tab-history a {
	background: transparent;
	color: #333;
	flex-basis: 25%;
}
.tab-history .van-sidebar-item--select {
	color: #fff;
}
.tab-history .van-sidebar-item--select::before {
	transform: translateX(-50%);
	left: 50%;
	top: auto;
	bottom: 10px;
	width: 30px;
	height: 3px;
	border-radius: 3px;
  background: #fff;
}
@media (max-width: 550px) {
	.item_list .line {
		font-size: 11px;
	}
	.item_list .period-number {
		font-size: 13px;
	}
}
</style>
